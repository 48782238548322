.about-sub-hero {
	background: $brand-accent;
	padding: 75px 0 51px;
	p {
		font-size: rem-calc(20);
		line-height: 1.25;
		margin-bottom: 24px;
	}
}

@include breakpoint(large) {
	.about-sub-hero {
		.text-cols {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 52px;
		}
	}
}