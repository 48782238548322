/*------------------------------------*\
    @group Hero
\*------------------------------------*/
.hero {
    height: 500px;
    background-color: #18506c;
    background-size: cover;
    padding: 150px 0 15px;
    position: relative;
    .inner {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 20;
    }
    h1 {
        font-family: $main-font;
        font-size: rem-calc(28);
        font-weight: $main-bold;
        color: $white;
        line-height: 1.18;
        padding: 0;
        margin: 0;
        border: 0;
        position: absolute;
        bottom: 0;
        left: 20px;
    }
    .hero-text {
        max-width: 900px;
        text-align: center;
    }
    &::after {
        content: '';
        width: 100%;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
}

.hero.hero-wide-text {
    .hero-text {
        max-width: 1200px;
    }
    h2 {
        font-size: rem-calc(24);
        line-height: 1.33;
        @include breakpoint(medium) {
            font-size: rem-calc(30);
            line-height: 1.33;
        }
    }
    .highlight-heading {
        color: $light-blue;
    }
}

.hero.hero-light-text {
    h2 {
        color: $white;
    }
}

@include breakpoint(large) {
    .hero {
        h1 {
            font-size: rem-calc(42);
            line-height: 1.24;
        }
    }
}

// Hero gradient scrims
.hero.bg-gradient-black-normal {
    &::before {
        height: 280px;
        background: linear-gradient(180deg, #182125 0%, rgba(24, 33, 37, 0) 100%);
    }
    &::after {
        height: 220px;
        background: linear-gradient(180deg, #182125 0%, rgba(24, 33, 37, 0) 100%);
        transform: matrix(1, 0, 0, -1, 0, 0);
    }
}

.hero.bg-gradient-black-short {
    &::before {
        height: 150px;
        background: linear-gradient(180deg, #182125 0%, rgba(24, 33, 37, 0) 100%);
    }
    &::after {
        height: 130px;
        background: linear-gradient(180deg, #182125 0%, rgba(24, 33, 37, 0) 100%);
        transform: matrix(1, 0, 0, -1, 0, 0);
    }
}

.hero.bg-gradient-blue-normal {
    &::before {
        height: 280px;
        background: linear-gradient(180deg, rgba(33, 54, 64, 0.8) 0%, rgba(33, 54, 64, 0) 100%);
    }
    &::after {
        height: 220px;
        background: linear-gradient(180deg, rgba(33, 54, 64, 0.8) 0%, rgba(33, 54, 64, 0) 100%);
        transform: matrix(1, 0, 0, -1, 0, 0);
    }
}


/*------------------------------------*\
    @group Hero Tall
\*------------------------------------*/
.hero-tall {
    height: 620px;
    background-color: #18506c;
    padding: 30px 0 100px;
    position: relative;
    .inner {
        height: 100%;
        display: flex;
        align-items: flex-end;
        position: relative;
        z-index: 20;
    }
    .hero-text {
        max-width: 440px;
        text-align: center;
        margin-left: auto;
        img {
            margin: 0;
        }
    }
    h1 {
        font-family: $main-font;
        font-size: rem-calc(30);
        font-weight: $main-bold;
        color: $white;
        text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.55);
        line-height: 1;
        padding: 0;
        margin: 0 0 10px;
        border: 0;
    }
    p {
        font-size: rem-calc(20);
        color: $white;
        line-height: 1;
        margin: 0;
    }
    &::before {
        content: '';
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
    &::after {
        content: '';
        width: 100%;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
}

@include breakpoint(large) {
    .hero-tall {
        height: 900px;
        padding: 75px 0 260px;
        .hero-text {
            max-width: 700px;
        }
        h1 {
            font-size: rem-calc(64);
        }
        p {
            font-size: rem-calc(40);
        }
    }
}

// Hero gradient scrims
.hero-tall.bg-gradient-black-tall {
    &::before {
        height: 320px;
        background: linear-gradient(180deg, #182125 0%, rgba(24, 33, 37, 0) 100%);
    }
    &::after {
        height: 300px;
        background: linear-gradient(180deg, #182125 0%, rgba(24, 33, 37, 0) 100%);
        transform: matrix(1, 0, 0, -1, 0, 0);
    }
}

@include breakpoint(large) {
    .hero-tall.bg-gradient-black-tall {
        &::before {
            height: 220px;
            background: linear-gradient(180deg, #182125 0%, rgba(24, 33, 37, 0) 100%);
        }
        &::after {
            height: 475px;
            background: linear-gradient(180deg, #182125 0%, rgba(24, 33, 37, 0) 100%);
            transform: matrix(1, 0, 0, -1, 0, 0);
        }
    }
}