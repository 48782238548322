.bg-text-grid {
	margin-bottom: $base-spacing;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px;
	@include breakpoint(400px) {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
}

.bg-text-grid-item {
	background-color: $light-blue;
	padding: 26px 30px;
	h3 {
		color: $brand;
		@include breakpoint(large) {
			font-size: 2.2rem;
		}
	}
	p {
		color: $brand;
		margin-bottom: 0;
	}
}