.icon-text-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	grid-column-gap: 15px;
	@include breakpoint(large) {
		grid-column-gap: 65px;
	}
}

.icon-text-grid-item {
	text-align: center;
	img {
		margin-bottom: 15px;
	}
	p {
		font-size: rem-calc(20);
		line-height: 1.25;
		@include breakpoint(large) {
			font-size: rem-calc(24);
			line-height: 1.33;
		}
	}
}