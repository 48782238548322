.bg-full-width-callout {
	height: 500px;
	background-size: cover;
	background-position: center top;
	padding: 30px 0;
	display: flex;
	align-items: flex-end;
	position: relative;
	&::after {
		content: '';
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, #182125 0%, rgba(24, 33, 37, 0) 100%);
		transform: matrix(1, 0, 0, -1, 0, 0);
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 1;
	}
	.inner {
		max-width: 480px;
		margin: 0;
		position: relative;
		z-index: 20;
		> *:last-child {
			margin-bottom: 0;
		}
	}
	p {
		font-family: $heading-font;
		font-weight: $heading-bold;
		font-size: rem-calc(24);
		color: $white;
		line-height: 1.33;
		font-style: italic;
		margin-bottom: 16px;
	}
}

@include breakpoint(large) {
	.bg-full-width-callout {
		height: 810px;
		padding: 75px 70px;
		.inner {
			padding: 0;
		}
	}
}

.bg-full-width-callout.alt {
	height: 500px;
	justify-content: center;
	align-items: center;
	&::after {
		display: none;
	}
	.inner {
		max-width: 756px;
	}
	p {
		font-family: $main-font;
		font-weight: $main-semibold;
		font-size: rem-calc(28);
		color: $gray;
		line-height: 1.25;
		font-style: normal;
		margin-bottom: 30px;
	}
	.button {
		margin-bottom: 0;
	}
}

@include breakpoint(large) {
	.bg-full-width-callout.alt {
		p {
			font-size: rem-calc(40);
		}
	}
}

// Callout background
.bg-full-width-callout {
	&.busy-street-walking {
		background-image: url('#{$imgcdn}/callouts/bg/busy_street_walking.jpg?auto=format&w=640');
		background-position: right top;
		background-color: #07090d;
	}
	&.apartment-complex-light {
		background-image: url('#{$imgcdn}/callouts/bg/apartment_complex_light.jpg?auto=format&w=640');
		background-position: right top;
		background-color: #fafbfd;
	}
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.bg-full-width-callout {
		&.busy-street-walking {
			background-image: url('#{$imgcdn}/callouts/bg/busy_street_walking.jpg?auto=format&w=640&q=20&dpr=2');
		}
		&.apartment-complex-light {
			background-image: url('#{$imgcdn}/callouts/bg/apartment_complex_light.jpg?auto=format&w=640&q=30&dpr=2');
		}
	}
}


@include breakpoint(medium) {
	.bg-full-width-callout {
		&.busy-street-walking {
			background-image: url('#{$imgcdn}/callouts/bg/busy_street_walking.jpg?auto=format&w=1000');
		}
		&.apartment-complex-light {
			background-image: url('#{$imgcdn}/callouts/bg/apartment_complex_light.jpg?auto=format&w=1000');
		}
	}

	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		.bg-full-width-callout {
			&.busy-street-walking {
				background-image: url('#{$imgcdn}/callouts/bg/busy_street_walking.jpg?auto=format&w=1000&q=20&dpr=2');
			}
			&.apartment-complex-light {
				background-image: url('#{$imgcdn}/callouts/bg/apartment_complex_light.jpg?auto=format&w=1000&q=30&dpr=2');
			}
		}
	}
}

@include breakpoint(large) {
	.bg-full-width-callout {
		&.busy-street-walking {
			background-image: url('#{$imgcdn}/callouts/bg/busy_street_walking.jpg?auto=format&w=1400');
		}
		&.apartment-complex-light {
			background-image: url('#{$imgcdn}/callouts/bg/apartment_complex_light.jpg?auto=format&w=1400');
		}
	}

	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		.bg-full-width-callout {
			&.busy-street-walking {
				background-image: url('#{$imgcdn}/callouts/bg/busy_street_walking.jpg?auto=format&w=1400&q=20&dpr=2');
			}
			&.apartment-complex-light {
				background-image: url('#{$imgcdn}/callouts/bg/apartment_complex_light.jpg?auto=format&w=1400&q=30&dpr=2');
			}
		}
	}
}

// Container width callout images
.bg-container-width-callout {
	max-width: 1300px;
	height: 500px;
	background-size: cover;
	background-position: center top;
	padding: 30px 0;
	margin: 0 auto;
	display: flex;
	align-items: center;
	position: relative;
	&::after {
		content: '';
		width: 100%;
		height: 100%;
		background: linear-gradient(90deg, #213640 0%, rgba(33, 54, 64, 0) 100%);
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 1;
	}
	.inner {
		max-width: 600px;
		margin: 0;
		position: relative;
		z-index: 20;
		> *:last-child {
			margin-bottom: 0;
		}
	}
	p {
		font-family: $heading-font;
		font-weight: $heading-bold;
		font-size: rem-calc(24);
		color: $white;
		line-height: 1.33;
		margin-bottom: 16px;
	}
}

@include breakpoint(large) {
	.bg-container-width-callout {
		height: 810px;
		padding: 60px;
		.inner {
			padding: 0;
		}
		p {
			font-size: rem-calc(30);
		}
	}
}


// Callout background
.bg-container-width-callout {
	&.new-york-skyline-bridge {
		background-image: url('#{$imgcdn}/callouts/bg/new_york_skyline_bridge_taxi.jpg?auto=format&w=640');
		background-position: right top;
		background-color: #07090d;
	}
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.bg-container-width-callout {
		&.new-york-skyline-bridge {
			background-image: url('#{$imgcdn}/callouts/bg/new_york_skyline_bridge_taxi.jpg?auto=format&w=640&q=20&dpr=2');
		}
	}
}


@include breakpoint(medium) {
	.bg-container-width-callout {
		&.new-york-skyline-bridge {
			background-image: url('#{$imgcdn}/callouts/bg/new_york_skyline_bridge_taxi.jpg?auto=format&w=1000');
		}
	}

	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		.bg-container-width-callout {
			&.new-york-skyline-bridge {
				background-image: url('#{$imgcdn}/callouts/bg/new_york_skyline_bridge_taxi.jpg?auto=format&w=1000&q=20&dpr=2');
			}
		}
	}
}

@include breakpoint(large) {
	.bg-container-width-callout {
		&.new-york-skyline-bridge {
			background-image: url('#{$imgcdn}/callouts/bg/new_york_skyline_bridge_taxi.jpg?auto=format&w=1400');
		}
	}

	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		.bg-container-width-callout {
			&.new-york-skyline-bridge {
				background-image: url('#{$imgcdn}/callouts/bg/new_york_skyline_bridge_taxi.jpg?auto=format&w=1400&q=20&dpr=2');
			}
		}
	}
}