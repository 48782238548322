/*----------------------------------------------------------*\
    Buttons

    Most button styles below can be combined.
    For example, you could have small brand accent button by
	combining the .button.brand-accent.small classes.

\*----------------------------------------------------------*/


.button,
form input[type="submit"] {
	background-color: $red;
	font-family: $main-font;
	font-weight: $main-bold;
	font-size: rem-calc(16);
	color: $white;
	line-height: 1.5;
	padding: 10px 20px;
	margin-bottom: $base-spacing;
	text-transform: uppercase;
	display: inline-block;
	transition: .3s background-color;
	&:hover,
	&:focus {
		background-color: darken($red, 7%);
		color: $white;
		text-decoration: none;
		cursor: pointer;
	}
}

.button.post-icon {
	display: inline-flex;
	align-items: center;
	.icon {
		margin-left: 4px;
	}
}

.button.small {
	font-size: rem-calc(14);
	padding: 6px 13px 10px;
}
.button.large {
	font-size: rem-calc(24);
	padding: 16px 23px 20px;
}
.button.short {
	padding-top: 7px;
	padding-bottom: 9px;
}
.button.tiny {
	font-size: rem-calc(12);
	line-height: 1;
	padding: 6px;
}

.button.light-blue {
	background-color: $light-blue;
	color: $brand;
	transition: background-color .3s, color .3s;
	&:hover, &:focus {
		background-color: darken($light-blue, 10%);
		color: $white;
	}
}

.button.white {
	background: $white;
	color: $red;
	&:hover, &:focus {
		background-color: $light-blue;
 	}
}