.page-header {
	.large-nav {
		font-size: 1rem;
		font-weight: $main-bold;
		a {
			color: $brand;
		}
		> ul {
			display: flex;
			align-items: center;
			> li {
				margin-left: 20px;
			}
		}
	}
	.extra-nav {
		margin-bottom: 10px;
		justify-content: flex-end;
	}
}

.page-header.light {
	background-color: $white;
}



@include breakpoint(1100px) {
	.page-header .large-nav {
		> ul > li {
			margin-left: 30px;
			&:first-child {
				margin-left: 20px;
			}
		}
	}
}

.extra-nav {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	.button {
		margin-right: 20px;
		margin-bottom: 0;
	}
	.icon-link {
		font-size: rem-calc(12);
		font-weight: $main-bold;
		color: $brand;
		text-transform: uppercase;
	}
}

nav a,
a.underline {
	position: relative;
	&::before {
		content: '';
		width: 100%;
		height: 2px;
		background-color: currentColor;
		position: absolute;
		top: 100%;
		left: 0;
		visibility: hidden;
		opacity: 0;
		transition: opacity .3s;
	}
	&.active, &:hover, &:focus {
		text-decoration: none;
		&::before {
			visibility: visible;
			opacity: 1;
		}
	}
}

.offscreen-nav {
	width: 100%;
	min-height: 100%;
	background: $white;
	padding: 30px 0;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
	position: absolute;
	transition: opacity .5s;
	.inner {
		max-width: 400px;
	}
	.logo img {
		width: 160px;
	}
	.header {
		padding-bottom: $content-sub-section-spacing-small;
		display: flex;
		justify-content: space-between;
		align-items: center;
		img {
			margin: 0;
		}
	}
	.small-nav {
		font-family: $heading-font;
		font-weight: $heading-bold;
		font-size: rem-calc(24);
		a {
			color: $brand;
			line-height: 1.18;
			display: inline-block;
		}
		li {
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.extra-nav {
		gap: 20px;
		.button {
			margin: 0;
		}
	}
	.extra-nav .icon-link {
		color: $red;
	}
}

body.nav-open .offscreen-nav {
	opacity: 1;
	visibility: visible;
	z-index: 200;
	transition: opacity .5s;
}