.cookie-notification-bar {
	background: $dark-blue;
	width: 100%;
	color: $white;
	padding-top: 20px;
	padding-bottom: 30px;
	box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.2);
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 199; // Above everything except open navs
	.button {
		margin-bottom: 0;
	}
	a:not(.button) {
		color: $white;
		text-decoration: underline;
		&:hover, &:focus {
			color: $light-blue;
		}
	}
}

@include breakpoint(850px) {
	.cookie-notification-bar {
		.inner {
			width: 100%;
			display: flex;
			align-items: center;
		}
		p {
			padding-right: 20px;
			margin-bottom: 0;
		}
	}
}