.transaction-info {
	h1 {
		margin-bottom: 30px;
	}
	img {
		margin-bottom: 20px;
	}
}

.transaction-info-details {
	margin-bottom: 20px;
	@include breakpoint(670px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 30px;
		.media {
			order: 1;
		}
	}
}

.transaction-loan-type {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 10px 30px;
	@include breakpoint(400px) {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	}
	p {
		margin-bottom: 10px;
	}
}

.transaction-info-highlights {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px;
	@include breakpoint(400px) {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
	ul {
		margin-top: 0;
	}
}