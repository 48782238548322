/*----------------------------------------------------------*\
   Tables

	The majority of the table styling comes from the
    Foundation settings file.

\*----------------------------------------------------------*/

table {
	border-collapse: collapse;
	margin-bottom: $base-spacing;
	tr > td:first-child {
		font-weight: $main-bold;
		padding-right: 20px;
	}
	td {
		padding: 10px;
		border: solid 1px #CCC;
	}
	tr:nth-child(even) td {
		background-color: $light-gray;
	}
}