.feature-detail-list {
	.feature-detail-item {
		border-bottom: solid 1px $brand-accent;
		margin-bottom: 10px;
		&:last-child {
			border: 0;
			margin-bottom: 24px;
			@include breakpoint(medium) {
				margin-bottom: 45px;
			}
		}
		p {
			margin-bottom: 10px;
		}
	}

}
