/*----------------------------------------------------------*\
    Header

    All styles for the main page header are below. This
    includes the navigation elements as well.

\*----------------------------------------------------------*/

.page-header {
    background: $brand;
    color: $white;
    padding: 30px 0;
    .navs {
        display: none;
    }
    .inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo {
        max-width: 300px;
        margin-right: 20px;
        svg {
            max-width: 100%;
        }
    }
    img {
        margin: 0;
    }
}

@include breakpoint(large) {
    .page-header {
        padding: 58px 0;
        .nav-toggle {
            display: none;
        }
        .navs {
            display: block;
        }
    }
    .offscreen-nav {
        display: none;
    }
    .page-header.short {
        padding: 38px 0;
    }
}

@include breakpoint(1260px) {
    .page-header {
        .logo {
            max-width: 454px;
        }
    }
}

// Hero template header
.hero-template .page-header,
.hero-tall-template .page-header {
    width: 100%;
    background: rgba($white, .8);
    z-index: 200;
    position: absolute;
    top: 0;
    left: 0;
}

@include breakpoint(large) {
    .hero-template .page-header,
    .hero-tall-template .page-header {
        top: 35px;
    }
}