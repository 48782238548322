@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/ProximaNova-Regular.woff2') format('woff2'),; /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/ProximaNova-Semibold.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/ProximaNova-Bold.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}