/*----------------------------------------------------------*\
    Page Footer Section

    This file contains all styles for the footer section.

\*----------------------------------------------------------*/

.page-footer {
    background: $brand;
    color: $white;
    padding: 35px 0;
    a {
        color: $white;
    }
    hr {
        border-color: #49565C;
    }
    .footer-nav {
        font-weight: $main-bold;
        margin-bottom: 30px;
        li {
            margin-bottom: 13px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .extra-nav {
        margin-bottom: 20px;
        gap: 20px;
        a {
            margin-bottom: 0;
        }
        a:not(.button) {
            color: $light-blue;
        }
        .button {
            margin: 0;
        }
    }
    .cta-wrapper {
        max-width: 385px;
        justify-self: end;
    }
    .cta {
        // background: $dark-blue;
        font-size: rem-calc(12);
        h2 {
            font-size: rem-calc(14);
            color: $white;
            line-height: 1.5;
            margin-bottom: 6px;
        }
        p {
            margin-bottom: 20px;
        }
        a {
            color: $light-blue;
        }
        .phone {
            font-family: $heading-font;
            font-size: rem-calc(30);
            font-weight: $main-semibold;
            word-break: break-all;
            line-height: 1;
            margin-bottom: 0;
        }
    }
    .sub-footer {
        font-size: 1rem;
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .copyright {
            padding-right: 20px;
            margin-bottom: 4px;
        }
    }
    .logo img {
        width: 350px;
    }
}

@include breakpoint(medium) {
    .page-footer {
        .sub-footer {
            .copyright {
                margin-bottom: 0;
            }
        }
    }
}

@include breakpoint(640px) {
    .page-footer {
        .main-footer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 40px;
        }
        .extra-nav {
            margin-bottom: 0;
            a {
                margin-bottom: 0;
            }
        }
    }
}

@include breakpoint(900px) {
    .page-footer {
        .main-footer {
            grid-template-columns: 1fr auto;
        }
        .footer-nav {
            margin-bottom: 17px;
            ul {
                display: flex;
                flex-wrap: wrap;
            }
            li {
                margin-right: 30px;
            }
        }
        .cta {
            font-size: rem-calc(14);
            .phone {
                font-size: rem-calc(40);
            }
        }
    }
}