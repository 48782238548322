.icon-bar {
	background-color: $light-gray;
	padding: 75px 0;
}

.icon-bar-items {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	grid-gap: 30px 16px;
}

.icon-bar-item {
	text-align: center;
	h2 {
		margin-bottom: 9px;
	}
	img {
		margin-bottom: 15px;
	}
	p {
		margin-bottom: 0;
	}
}