.team-cards {
	margin-bottom: $base-spacing;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: 30px;
}

.team-card {
	max-width: 280px;
	margin: 0 auto;
	a {
		color: $brand;
		text-decoration: none;
		&:hover, &:focus {
			.action-icon img {
				transform: rotate(360deg);
			}
		}
	}
}

.team-card-image {
	position: relative;
	img {
		margin-bottom: 6px;
	}
	.action-icon {
		background-color: $white;
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 10;
		img {
			transition: transform .5s;
		}
	}
}

.team-card-name {
	font-family: $heading-font;
	font-size: rem-calc(18);
	font-weight: $heading-bold;
	line-height: 1.5;
	margin: 0;
}

.team-card-title {
	font-size: rem-calc(16);
	line-height: 1.5;
	margin: 0;
}

// Team bio page
.team-bio-image {
	text-align: center;
}

@include breakpoint(820px) {
	.team-bio {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-gap: 30px;
	}
}

// Team bio contact info for bottom of individual page
.team-bio-contact-info {
	> div {
		font-size: rem-calc(14);
		line-height: 1.5;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 20px;
	}
	.icon {
		width: 24px;
		margin-right: 10px;
		text-align: center;
	}
	img {
		height: 16px;
		margin: 0;
	}
	hr {
		margin-top: 10px;
		margin-bottom: 40px;
	}
}

@include breakpoint(medium) {
	.team-bio-contact-info {
		> div {
			margin-right: 20px;
			display: inline-block;
		}
		.icon {
			width: auto;
			margin-right: 10px;
			text-align: left;
		}
	}
}