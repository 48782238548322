.bg-cards {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px;
	margin-bottom: $base-spacing;
	@include breakpoint(400px) {
		grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
	}
}

.bg-card {
	max-width: 414px;
	margin: 0 auto;
	position: relative;
	@include breakpoint(large) {
		margin: 0;
	}
	a {
		display: block;
		position: relative;
		z-index: 10;
		&::before {
			content: '';
			width: 100%;
			height: 70px;
			background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 2;
		}
		&:hover, &:focus {
			.action-icon img {
				transform: rotate(360deg);
			}
		}
	}
	img {
		margin: 0;
		position: relative;
		z-index: 1;
	}
	.heading {
		font-family: $heading-font;
		font-size: rem-calc(20);
		font-weight: $heading-bold;
		color: $white;
		line-height: 1.25;
		padding-right: 30px;
		margin: 0;
		position: absolute;
		left: 20px;
		bottom: 10px;
		z-index: 20;
		@include breakpoint(large) {
			font-size: rem-calc(24);
			line-height: 1.33;
		}
	}
	.action-icon {
		background-color: $white;
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 10;
		img {
			transition: transform .5s;
		}
	}
}


.bg-card.tall-heading {
	a::before {
		height: 125px;
	}
	p {
		margin: 0;
	}
	.heading {
		position: relative;
		left: auto;
		bottom: auto;
	}
	.details {
		color: $white;
	}
	.bg-card-text {
		padding-right: 35px;
		position: absolute;
		bottom: 8px;
		left: 20px;
		z-index: 20;
	}
}

// Text cards
.text-cards {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px;
	@include breakpoint(400px) {
		grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
	}
}

.text-card {
	max-width: 414px;
	margin: 0 auto;
	position: relative;
	display: flex;
	flex-direction: column;
	img {
		margin-bottom: 0;
	}
	.content {
		background-color: $brand-accent;
		height: 100%;
		padding: 30px;
		> *:last-child {
			margin-bottom: 0;
		}
	}
}